import {UrlBuilder} from "../../../common/utils/url";
import {html, LitElement, type TemplateResult} from "lit";
import {customElement, property} from "lit/decorators.js";
import Styles from "./newsTagLink.lit.scss";
import {SEARCH_QUERY_PARAMETER_NAME} from "./searchGroup";

@customElement("eop-news-tag-link")
export class EopNewsTagLink extends LitElement {

    public static readonly styles = Styles;

    @property()
    private url: string;
    @property()
    private text: string;

    public render(): TemplateResult {
        return html`
            <div class="tag-container">
                <div class="tag">
                    <a href=${this.getTargetUrl()}>${this.text}</a>
                </div>
            </div>
        `;
    }

    private getTargetUrl(): string {
        return new UrlBuilder(this.url)
            .withQueryParameter(SEARCH_QUERY_PARAMETER_NAME, this.text)
            .build();
    }
}