import {customElement, property} from "lit/decorators.js";
import {html, type TemplateResult} from "lit";
import Styles from "./newsSearchPreview.lit.scss";
import {Search} from "../search";
import {resolve} from "../../../../container";
import {NewsSearchBackend} from "../newsSearchBackend";

@customElement("eop-news-search-preview")
export class EopNewsSearchPreview extends Search {

    public static readonly styles = Styles;

    @property({attribute: "url"})
    private resultsPageUrl: string;

    public constructor(
        backend: NewsSearchBackend = resolve(NewsSearchBackend)
    ) {
        super();
        this.searchFacade.registerBackend(backend);
    }


    public render(): TemplateResult {
        return html`
            <eop-overlay-image-spinner event="search" class="content-search-spinner"></eop-overlay-image-spinner>
            <eop-overlay-spinner event="more"></eop-overlay-spinner>
            ${this.renderResultsLabel()}
            <eop-news-search-preview-tiles .resultsPageUrl=${this.resultsPageUrl} class="tiles"></eop-news-search-preview-tiles>
            <div class="footer">
                ${this.renderMoreButton()}
            </div>`;
    }

    protected override renderResultsLabel(): TemplateResult | null {
        if (this.searchFacade.appliedFilters().size === 0) {
            return null;
        }
        return html`
            <div class="results-label">
                ${this.resultsText}
                <eop-search-filter-tags></eop-search-filter-tags>
            </div>`;
    }
}
