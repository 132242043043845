import {customElement, property} from "lit/decorators.js";
import {html, type TemplateResult} from "lit";
import {resolve} from "../../../../container";
import {Dictionary} from "../../../../page/elements/dictionary";
import Styles from "./newsSearchResults.lit.scss";
import {NewsSearchBackend} from "../newsSearchBackend";
import {FilteredSearch} from "../filteredSearch";

@customElement("eop-news-search-results")
export class EopNewsSearchResults extends FilteredSearch {

    public static readonly styles = Styles;

    @property({attribute: "back-link-href"})
    private backLinkHref: string;

    public constructor(
        backend: NewsSearchBackend = resolve(NewsSearchBackend)
    ) {
        super();
        this.searchFacade.registerBackend(backend);
    }


    public render(): TemplateResult {
        return html`
            <eop-overlay-spinner event="search,more" class="content-search-spinner"></eop-overlay-spinner>
            ${this.renderResultsLabel()}
            <eop-news-search-results-tiles class="tiles"></eop-news-search-results-tiles>
            ${this.renderSearchTags()}
            <div class="footer">
                ${this.renderMoreButton()}
                ${this.renderBackLink()}
            </div>`;
    }

    private renderSearchTags(): TemplateResult | null {
        if (this.searchFacade.totalCount() !== 0) {
            return null;
        }

        return html`
            <eop-search-tags .tags=${this.tags} .targetUrl=${this.targetUrl} class="initial" data-eventelement="tags"></eop-search-tags>`;
    }

    private renderBackLink(): TemplateResult | null {
        if (!this.searchFacade.activeGroup() || !this.backLinkHref) {
            return null;
        }

        const label = Dictionary.of(this).translate("NEWSROOM_BACK");
        return html`
            <div class="content-search-back-link">
                <a href=${this.backLinkHref} class="link link-prev">${label}</a>
            </div>`;
    }
}
