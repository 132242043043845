import {Initializing} from "../../../common/elements";
import {ManagingResources} from "../../../common/lifetime";
import {html, LitElement, type TemplateResult} from "lit";
import {property, state} from "lit/decorators.js";
import {schedule} from "../../../common/utils/promises";
import {ENTRY_COUNT_QUERY_PARAMETER_NAME, NEWS_SEARCH_PHRASE_EVENT} from "./searchGroup";
import {resolve} from "../../../container";
import {SearchFacade} from "./searchFacade";
import {QueryParameters} from "../../../common/queryParameters";
import {EventBus} from "../../../common/eventBus";
import {Dictionary} from "../../../page/elements/dictionary";

const RESULT_COUNT_PLACEHOLDER = "[COUNT]";

export abstract class Search extends Initializing(ManagingResources(LitElement)) {
    protected dictionary: Dictionary;

    @property({attribute: "show-more"})
    protected showMore: boolean;
    @property({attribute: "language"})
    protected language: string;
    @property({attribute: "number", type: Number})
    protected number: number;
    @property({attribute: "character-limit", type: Number})
    protected characterLimit: number;
    @property({attribute: "archive"})
    protected archive: boolean;
    @state()
    protected hasMore: boolean;
    @state()
    protected resultsText: string;

    public constructor(
        protected searchFacade: SearchFacade = resolve(SearchFacade),
        protected queryParameters: QueryParameters = resolve(QueryParameters),
        protected eventBus: EventBus = resolve(EventBus)
    ) {
        super();
        this.hasMore = false;
        this.resultsText = "";
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.dictionary = Dictionary.of(this);

        this.searchFacade.configure(this.language, this.number, this.characterLimit, this.archive);
        this.searchFacade.onUpdateEntryCount(count => this.updateEntryCount(count));
        this.searchFacade.onNewEntries(newEntries => this.updateMore(newEntries.hasMoreEntries));

        this.eventBus.on(NEWS_SEARCH_PHRASE_EVENT, event => {
            schedule(this.nextSearchPhrase(event.data.searchPhrase)).as("do-search");
        }, this);

        schedule(this.initSearch()).as("init-search");
    }

    protected renderMoreButton(): TemplateResult | null {
        if (!this.showMore || !this.hasMore) {
            return null;
        }

        return html`
            <div class="fetch-more-button">
                <button type="button" @click=${this.fetchMore} class="uni-button primary" data-eventelement="button">
                    <eop-msg key="CONTENT_SEARCH_FETCH_MORE"></eop-msg>
                </button>
            </div>`;
    }

    protected fetchMore(): void {
        schedule(this.searchFacade.fetchMore()).as("do-search");
    }

    protected renderResultsLabel(): TemplateResult | null {
        return html`
            <div class="results-label">
                ${this.resultsText}
                <eop-search-filter-tags></eop-search-filter-tags>
            </div>`;
    }

    protected updateResultsText(): void {
        const subKey = this.searchFacade.totalCount() === 1 ? "1" : "_";
        this.resultsText = this.dictionary.translate("CONTENT_SEARCH_RESULTS_LABEL", subKey, {[RESULT_COUNT_PLACEHOLDER]: this.searchFacade.totalCount()});
    }

    public async initSearch(): Promise<void> {
        const entryCount = this.entryCountFromQueryParameters();
        await this.searchFacade.search(entryCount);
        this.updateResultsText();
        this.initialized();
    }

    protected updateEntryCount(count: number): void {
        this.updateResultsText();
        this.updateMore(this.searchFacade.hasMoreEntries());
        this.queryParameters.set(ENTRY_COUNT_QUERY_PARAMETER_NAME, String(count));
    }

    protected updateMore(hasMore: boolean): void {
        this.hasMore = hasMore;
    }

    public async nextSearchPhrase(searchPhrase?: string): Promise<void> {
        await this.searchFacade.nextSearchPhrase(searchPhrase);
    }

    protected entryCountFromQueryParameters(): number | undefined {
        try {
            return this.queryParameters.getString(ENTRY_COUNT_QUERY_PARAMETER_NAME)?.toInt();
        } catch (e) {
            return undefined;
        }
    }
}