import {prefixWithPipe} from "../prefixWithPipe";
import type {PropertyMap} from "../../../common/utils/objects";
import {VisitRecord} from "./visitRecord";
import {resolve} from "../../../container";
import {Dictionary} from "../../../page/elements/dictionary";
import {html, LitElement, type TemplateResult} from "lit";
import {property} from "lit/decorators.js";
import {LocatedDates} from "../../../common/utils/dates/locatedDates";
import {unsafeHTML} from "lit/directives/unsafe-html.js";
import {ModalDialogFactory} from "../../../page/elements/dialog/modalDialog";
import {DisclaimerDialogFactory} from "../../../page/elements/dialog/disclaimerDialog";
import {LightboxDialogFactory} from "../../../page/elements/dialog/lightboxDialog";
import type {FeedEntry} from "../feedEntry";

const HOVER_CLASS = "hover";

const TYPE_CLASS: PropertyMap = {
    download: "download-tile",
    default: ""
};

export abstract class SearchTile extends LitElement {

    @property()
    protected entry: FeedEntry;
    @property()
    protected groupName: string;

    private dictionary: Dictionary;

    protected constructor(
        private locatedDates: LocatedDates = resolve(LocatedDates),
        private visitRecord: VisitRecord = resolve(VisitRecord),
        private modalDialog: ModalDialogFactory = resolve(ModalDialogFactory)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.dictionary = Dictionary.of(this);
    }

    protected renderTile(): TemplateResult {
        return html`
            <a href=${this.entry.data.url}
               @mouseover=${this.addHoverClass}
               @mouseout=${this.removeHoverClass}
               @click=${this.handleClick}
               class="tile-content ${this.typeClass()}"
               data-tracking-label="content-search-tile-${this.headlineText()}">
                <div class=tile-image>
                    ${this.renderImageElement()}
                </div>
                <div class=tile-text>
                    <div class=tile-top>
                        ${this.renderFileExtension()}
                        <div class=metadata>
                            ${this.renderNewLabel()}
                            ${this.getDate()}${this.getNewsType()}
                        </div>
                        ${this.renderHeadline()}
                        ${this.renderSubHeadline()}
                        ${this.renderFileSize()}
                    </div>
                    <div class=tile-bottom>
                        ${this.renderTopicLinks()}
                    </div>
                </div>
            </a>`;
    }

    private isNotVisited(): boolean {
        return this.entry.data.isNew && this.entry.recentlyVisited === false;
    }

    protected abstract renderImageElement(): TemplateResult | null;

    protected abstract renderTopicLinks(): TemplateResult | null;

    protected abstract showDescription(): boolean;

    private isDownloadTile(): boolean {
        return this.entry.data.contentType === "download";
    }

    protected removeHoverClass(e: Event): void {
        e.stopPropagation();
        this.classList.remove(HOVER_CLASS);
    }

    protected addHoverClass(e: Event): void {
        e.stopPropagation();
        this.classList.add(HOVER_CLASS);
    }

    private renderFileExtension(): TemplateResult | null {
        if (!this.isDownloadTile()) {
            return null;
        } else {
            return html`<span class="file-extension reverse-background">${this.entry.data.fileMetadata?.extension}</span>`;
        }
    }

    private renderNewLabel(): TemplateResult | null {
        if (!this.isNotVisited()) {
            return null;
        }

        return html`<span class=label-new>${this.dictionary.translate("CONTENT_SEARCH_NEW")}</span>`;
    }

    private renderFileSize(): TemplateResult | null {
        if (!this.isDownloadTile()) {
            return null;
        }

        return html`<span class=file-size>(${this.entry.data.fileMetadata?.size})</span>`;
    }

    private renderHeadline(): TemplateResult | null {
        return html`
            <div class=message-headline>
                <div class=headline-text>${unsafeHTML(this.headlineText())}</div>
            </div>`;
    }

    protected abstract headlineText(): string;

    private renderSubHeadline(): TemplateResult | null {
        if (!this.showDescription()) {
            return null;
        }

        return html`
            <div class=message-description>${unsafeHTML(this.subHeadlineText())}</div>`;
    }

    protected abstract subHeadlineText(): string;

    private handleClick(event: Event): void {
        if (this.entry.data.disclaimer) {
            event.preventDefault();
            this.modalDialog.create()
                .withContent(DisclaimerDialogFactory.create(
                    this.entry.data.disclaimer.headline,
                    this.entry.data.disclaimer.content,
                    this.entry.data.url,
                    this.dictionary.translate("MSG_DISCLAIMER_ACCEPT"),
                    this.dictionary.translate("MSG_DISCLAIMER_DECLINE")
                )).open();
        } else if (this.entry.data.lightboxImageUrl) {
            event.preventDefault();
            this.modalDialog.create()
                .withContent(LightboxDialogFactory.create(
                    this.entry.data.headline ?? "",
                    this.entry.data.subHeadline ?? "",
                    this.entry.data.url,
                    this.entry.data.lightboxImageUrl,
                    this.entry.data.imageAltText,
                    this.dictionary.translate("MSG_DOWNLOAD_IMAGE")
                )).open();
        }
        if (this.isNotVisited()) {
            this.visitRecord.addVisitedURL(this.entry.data.url);
        }
    }

    private typeClass(): string {
        return TYPE_CLASS[this.entry.data.contentType] ?? TYPE_CLASS.default;
    }

    private getDate(): string {
        return this.locatedDates.toLocalDateString(new Date(this.entry.data.publishedDate));
    }

    private getNewsType(): string {
        return prefixWithPipe(this.entry.data.newsType);
    }

}