import type {TemplateResult} from "lit";
import {html} from "lit";
import {autoRegister} from "../../../container";

@autoRegister()
export class ContentSearchImages {
    public imageWithStaticAspectRatio(src: string, alt: string): TemplateResult {
        if (this.isResponsiveImage(src)) {
            return html`
                <eop-responsive-image
                        .imageSrc=${src}
                        .imageAlt=${alt}
                ></eop-responsive-image>
            `;
        } else {
            return html`
                <eop-plain-image
                        src=${src}
                        alt=${alt}
                        class="plain-image-container eop-image"
                ></eop-plain-image>
            `;
        }
    }

    public smallImage(src7x4: string, src4x3: string, alt: string): TemplateResult {
        if (this.isResponsiveImage(src7x4)) {
            return html`
                <eop-responsive-image
                        .imageSrc=${src4x3}
                        src-768=${src7x4}
                        .imageAlt=${alt}
                ></eop-responsive-image>
            `;
        } else {
            return html`
                <eop-plain-image
                        src=${src4x3}
                        src-768=${src7x4}
                        alt=${alt}
                        class="plain-image-container eop-image"
                ></eop-plain-image>
            `;
        }
    }

    public largeImage(src7x4: string, src3x4: string, alt: string): TemplateResult {
        if (this.isResponsiveImage(src7x4)) {
            return html`
                <eop-responsive-image
                        .imageSrc=${src3x4}
                        src-768=${src7x4}
                        src-1536=${src3x4}
                        .imageAlt=${alt}
                ></eop-responsive-image>
            `;
        } else {
            return html`
                <eop-plain-image
                        src=${src3x4}
                        src-768=${src7x4}
                        src-1536=${src3x4}
                        alt=${alt}
                        class="plain-image-container eop-image"
                ></eop-plain-image>
            `;
        }
    }

    public doubleImage(src7x4: string, src3x4: string, alt: string): TemplateResult {
        if (this.isResponsiveImage(src7x4)) {
            return html`
                <eop-responsive-image
                        .imageSrc=${src3x4}
                        src-768=${src7x4}
                        src-1024=${src3x4}
                        .imageAlt=${alt}
                ></eop-responsive-image>
            `;
        } else {
            return html`
                <eop-plain-image
                        src=${src3x4}
                        src-768=${src7x4}
                        src-1024=${src3x4}
                        alt=${alt}
                        class="plain-image-container eop-image"
                ></eop-plain-image>
            `;
        }
    }

    public isResponsiveImage(src: string): boolean {
        return !src.includes(".gif") && !src.includes(".svg");
    }
}